import './filter.scss';

import axios from 'axios';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createElementFromHTML, getFormData } from './../../utilities/js/helper';

class Filter {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-filter',
            allowUpdateUri: true,
            filterAttr: 'filter',
            filterBarAttr: 'bar',
            filterBarScrollerAttr: 'bar-scroller',
            filterBarItemsAttr: 'bar-items',
            barItemAttr: 'bar-item',
            selectedAttr: 'selected',
            resultsAttr: 'results',
            toggleAttr: 'toggle',
            groupsAttr: 'groups',
            groupToggleAttr: 'group-toggle',
            groupHandleAttr: 'group-handle',
            closeAttr: 'close',
            removeAttr: 'remove'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$filter = $element;
        this.$filterToggle = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.$results = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.resultsAttr + '"]');
        this.$filterBar = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterBarAttr + '"]');
        this.$filterRemove = this.$filterBar.querySelector('[' + this.settings.initAttr + '="' + this.settings.removeAttr + '"]');
        this.$filterItems = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterAttr + '"]');
        this.$filterSelected = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.selectedAttr + '"]');
        this.filterUri = this.$filter.getAttribute('action');
        this.$siteHeader = document.querySelector('[data-siteheader="root"]');
        this.filterBarScrollPosition = 0;
        this.$loadingSpinner = createElementFromHTML(window.OPTIONS.loader);

        this.allowUpdateUri = this.settings.allowUpdateUri;
        if (this.$filter.getAttribute('no-update-uri') !== null) {
            this.allowUpdateUri = false;
        }

        this.resizeHandler = window.resizeHandler;

        this.documentClickTimeout = null;
        this.filterOpen = false;

        this.initialize();
    }

    initialize () {
        this.setEvents();

        if (this.$filterBar) {
            this.initFilterBar();
        }
    }

    setEvents () {
        if (this.$siteHeader) {
            if (this.$filterToggle) {
                this.$filterToggle.addEventListener('change', () => {
                    clearTimeout(this.documentClickTimeout);

                    if (this.$filterToggle.checked === true) {
                        this.$siteHeader.classList.add('is--filter-open');
                        disableBodyScroll(this.$filterItems);

                        this.documentClickTimeout = window.setTimeout(() => {
                            this.filterOpen = true;
                        }, 500);
                    } else {
                        this.$siteHeader.classList.remove('is--filter-open');
                        enableBodyScroll(this.$filterItems);
                        this.filterOpen = false;
                    }
                });
            }
        }

        this.$filterItems.addEventListener('change', (e) => {
            const $element = e.target;
            if ($element.getAttribute('formtrigger') === 'ignore') {
                e.preventDefault();
            } else {
                this.updateList();
            }

            if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.groupToggleAttr + '"]')) {
                if ($element.checked === true) {
                    $element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }
        });

        this.$filterItems.addEventListener('click', (e) => {
            const $element = e.target;
            if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.closeAttr + '"]')) {
                this.$filterToggle.checked = false;

                const toggleUpdateEvent = new CustomEvent('change', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                this.$filterToggle.dispatchEvent(toggleUpdateEvent);

                e.preventDefault();
            }

            if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.groupHandleAttr + '"]')) {
                const $target = document.getElementById($element.getAttribute('for'));
                if ($target.checked === true) {
                    $target.checked = false;
                } else {
                    $target.checked = true;
                }

                const targetUpdateEvent = new CustomEvent('change', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });

                $target.dispatchEvent(targetUpdateEvent);

                e.preventDefault();
            }
        });

        this.$filterBar.addEventListener('click', (e) => {
            const $element = e.target;
            if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.barItemAttr + '"]') !== null) {
                if (this.$filterToggle.checked === false) {
                    this.$filterToggle.checked = true;

                    const toggleUpdateEvent = new CustomEvent('change', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    this.$filterToggle.dispatchEvent(toggleUpdateEvent);
                }
            }

            if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.removeAttr + '"]') !== null) {
                const $currentFilter = this.$filterItems.querySelectorAll('input:checked:not([formtrigger="ignore"])');

                for (let i = 0; i < $currentFilter.length; i++) {
                    $currentFilter[i].checked = false;

                    if (i === $currentFilter.length - 1) {
                        const filterUpdateEvent = new CustomEvent('change', {
                            view: window,
                            bubbles: true,
                            cancelable: false
                        });
                        this.$filterItems.dispatchEvent(filterUpdateEvent);
                    }
                }
            }
        });

        document.addEventListener('click', (e) => {
            if (this.filterOpen === true) {
                if (!this.$filterItems.contains(e.target) && e.target !== this.$filterItems) {
                    this.closeFilter();

                    e.preventDefault();
                }
            }
        });
    }

    closeFilter () {
        this.$filterToggle.checked = false;

        const toggleUpdateEvent = new CustomEvent('change', {
            view: window,
            bubbles: true,
            cancelable: false
        });
        this.$filterToggle.dispatchEvent(toggleUpdateEvent);
    }

    initFilterBar () {
        const $scroller = this.$filterBar.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterBarScrollerAttr + '"]');

        if ($scroller) {
            const checkPosition = () => {
                const maxDistance = $scrollerItems.offsetWidth - $scroller.offsetWidth;

                if (this.filterBarScrollPosition === 0) {
                    $scrollWrapper.classList.add('is--start');
                } else if (this.filterBarScrollPosition === maxDistance) {
                    $scrollWrapper.classList.add('is--end');
                } else {
                    $scrollWrapper.classList.remove('is--start');
                    $scrollWrapper.classList.remove('is--end');
                }
            };

            const checkMaxDistance = () => {
                const maxDistance = $scrollerItems.offsetWidth - $scroller.offsetWidth;

                if (maxDistance > 0) {
                    $scrollWrapper.classList.add('is--scrollable');
                } else {
                    $scrollWrapper.classList.remove('is--scrollable');
                }
            };

            const $scrollerItems = $scroller.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterBarItemsAttr + '"]');
            const $scrollWrapper = $scroller.parentNode;

            const $scrollLeft = document.createElement('div');
            $scrollLeft.classList.add('filter-bar__scroll');
            $scrollLeft.classList.add('is--left');

            const $scrollLeftButton = document.createElement('button');
            $scrollLeftButton.classList.add('filter-bar__scroll-button');
            $scrollLeft.appendChild($scrollLeftButton);

            const $scrollRight = document.createElement('div');
            $scrollRight.classList.add('filter-bar__scroll');
            $scrollRight.classList.add('is--right');

            const $scrollRightButton = document.createElement('button');
            $scrollRightButton.classList.add('filter-bar__scroll-button');
            $scrollRight.appendChild($scrollRightButton);

            $scrollWrapper.insertBefore($scrollLeft, $scroller);
            $scrollWrapper.appendChild($scrollRight);

            $scroller.addEventListener('scroll', () => {
                this.filterBarScrollPosition = Math.ceil($scroller.scrollLeft);

                checkPosition();
            }, { passive: true });

            $scrollLeftButton.addEventListener('click', (e) => {
                $scroller.scrollTo({
                    top: 0,
                    left: this.filterBarScrollPosition - 200,
                    behavior: 'smooth'
                });

                e.preventDefault();
            });

            $scrollRightButton.addEventListener('click', (e) => {
                $scroller.scrollTo({
                    top: 0,
                    left: this.filterBarScrollPosition + 200,
                    behavior: 'smooth'
                });

                e.preventDefault();
            });

            $scroller.scrollTo({
                top: 0,
                left: this.filterBarScrollPosition
            });

            checkMaxDistance();
            checkPosition();

            this.resizeHandler.customFunctions.push(() => {
                checkMaxDistance();
                checkPosition();
            });
        }
    }

    updateUri (formData) {
        if (this.allowUpdateUri === true) {
            let uri = this.filterUri;

            const serializedData = Array.from(
                formData,
                e => e.map(encodeURIComponent).join('=')
            ).join('&');

            if (serializedData) {
                uri = uri + '?' + serializedData;
            }

            this.setHistory(uri);
        }
    }

    updateList () {
        const formData = getFormData(this.$filterItems);

        this.updateUri(formData);

        if (this.$filterItems) {
            this.$filterItems.appendChild(this.$loadingSpinner.cloneNode(true));
        }
        if (this.$results) {
            this.$results.appendChild(this.$loadingSpinner.cloneNode(true));
        }
        if (this.$filterSelected) {
            this.$filterSelected.appendChild(this.$loadingSpinner.cloneNode(true));
        }
        if (this.$filterBar) {
            this.$filterBar.appendChild(this.$loadingSpinner);
        }

        axios({
            method: 'POST',
            url: this.filterUri,
            data: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then((result) => {
                if (result && result.data) {
                    const $result = createElementFromHTML(result.data);

                    if (this.$filterItems) {
                        const $filterResult = $result.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterAttr + '"]');
                        this.$filterItems.innerHTML = $filterResult.innerHTML;
                    }

                    if (this.$results) {
                        const $itemsResult = $result.querySelector('[' + this.settings.initAttr + '="' + this.settings.resultsAttr + '"]');
                        const itemsCount = $itemsResult.getAttribute('data-filter-items');

                        if (itemsCount) {
                            this.$results.setAttribute('data-filter-items', itemsCount);
                        }
                        this.$results.innerHTML = $itemsResult.innerHTML;
                    }

                    if (this.$filterBar) {
                        const $filterBarResult = $result.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterBarAttr + '"]');
                        this.$filterBar.innerHTML = $filterBarResult.innerHTML;
                    }

                    if (this.$filterSelected) {
                        const $filterSelected = $result.querySelector('[' + this.settings.initAttr + '="' + this.settings.selectedAttr + '"]');
                        this.$filterSelected.innerHTML = $filterSelected.innerHTML;
                    }

                    const contentUpdateEvent = new CustomEvent('content.loaded', {
                        view: window,
                        bubbles: true,
                        cancelable: false,
                        detail: {
                            $context: this.$results
                        }
                    });
                    window.dispatchEvent(contentUpdateEvent);

                    window.checkInview(window.eventScroller);

                    if (this.$filterBar) {
                        this.initFilterBar();
                    }
                }
            })
            .catch((error) => {
                throw Error(error);
            });
    }

    setHistory (uri) {
        history.pushState(null, document.title, uri);
    }
}

export { Filter };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$filter = $context.querySelectorAll('[data-filter="root"]');
        for (let i = 0; i < $$filter.length; i++) {
            const $filter = $$filter[i];

            const filterAPI = new Filter($filter);
            $filter.API = filterAPI;
        }
    }
});
